import { call } from ".";

// charge
/**
 * add plan
 * @param {string} name
 * @returns {Promise<Object>} plan_id
 */

const addPlan = function(idToken, name) {
  return call("addPlan", idToken, {
    name: name
  });
};

/**
 * set plan
 * @param {string} planID
 * @param {object} setData (name: string, start_time: time, end_time: time, one_time_fee: float, regular_fee: float, regular_fee_cycle: month/year, transaction_fees_percentage: [{}], allow_telegram_bot_chat: boolean, allow_one_page_web: boolean, allow_payment_cash: boolean, allow_payment_cash_on_delivery: boolean, allow_payment_atm: boolean, allow_payment_tappay: boolean, allow_payment_ecpay: boolean, allow_shipping_pickup: boolean, allow_shipping_logistics: boolean, allow_published_item_quantity: int, allow_push_store_message_interval: string, allow_push_store_message_quantity: int)
 * @returns {Promise<Object>} plan_id
 */

const setPlan = function(idToken, planID, setData) {
  return call("setPlan", idToken, {
    plan_id: planID,
    set_data: setData
  });
};

/**
 * publish plan
 * @param {string} planID
 * @returns {Promise<String>} success
 */

const publishPlan = function(idToken, planID) {
  return call("publishPlan", idToken, {
    plan_id: planID
  });
};

/**
 * delist plan
 * @param {string} planID
 * @returns {Promise<String>} success
 */

const delistPlan = function(idToken, planID) {
  return call("delistPlan", idToken, {
    plan_id: planID
  });
};

/**
 * delete plan
 * @param {string} planID
 * @returns {Promise<String>} success
 */

const deletePlan = function(idToken, planID) {
  return call("deletePlan", idToken, {
    plan_id: planID
  });
};

/**
 * get plan
 * @param {string} planID
 * @returns {Promise<String>} success
 */

const getPlan = function(idToken, planID) {
  return call("getPlan", idToken, {
    plan_id: planID
  });
};

/**
 * get plans
 * @param {string} status (unpublished, published, delisted, not-started, started, expired)
 * @returns {Promise<Object>} status, plan_ids, plans
 */

const getPlans = function(idToken, status) {
  return call("getPlans", idToken, {
    status: status
  });
};

/**
 * suspend user plan
 * @param {string} userID
 * @param {boolean} suspend
 * @returns {Promise<String>} success
 */

const suspendUserPlan = function(idToken, userID, suspend) {
  return call("suspendUserPlan", idToken, {
    user_id: userID,
    suspend: suspend
  });
};

/**
 * set user plan
 * @param {string} userID
 * @param {string} planID ?
 * @param {time} startTime ?
 * @param {time} endTime ?
 * @returns {Promise<Object>} plan_id, user_ids
 */

const setUserPlan = function(idToken, userID, planID, startTime, endTime) {
  return call("setUserPlan", idToken, {
    user_id: userID,
    plan_id: planID,
    start_time: startTime,
    end_time: endTime
  });
};

/**
 * get user plan
 * @param {string} userID
 * @returns {Promise<Object>} plan_of_user
 */

const getUserPlan = function(idToken, userID) {
  return call("getUserPlan", idToken, {
    user_id: userID
  });
};

/**
 * get user plan histories
 * @param {string} userID
 * @returns {Promise<Object>} plan_of_user {[]}
 */

const getUserPlanHistories = function(idToken, userID) {
  return call("getUserPlanHistories", idToken, {
    user_id: userID
  });
};

/**
 * get user IDs by plan
 * @param {string} planID
 * @returns {Promise<Object>} plan_id, user_ids
 */

const getUserIDsByPlan = function(idToken, planID) {
  return call("getUserIDsByPlan", idToken, {
    plan_id: planID
  });
};

/**
 * get user plans by plan
 * @param {string} planID
 * @returns {Promise<Object>} []plan_of_user{}
 */

const getUserPlansByPlan = function(idToken, planID) {
  return call("getUserPlansByPlan", idToken, {
    plan_id: planID
  });
};

export {
  addPlan,
  setPlan,
  publishPlan,
  delistPlan,
  deletePlan,
  getPlan,
  getPlans,
  setUserPlan,
  suspendUserPlan,
  getUserPlan,
  getUserPlanHistories,
  getUserIDsByPlan,
  getUserPlansByPlan
};
