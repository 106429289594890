import { call } from ".";

/**
 * calc billing
 * @param {string} userID
 * @param {int} year
 * @param {int} month
 * @param {string} startDate
 * @param {string} endDate
 * @param {boolean} create
 * @param {time} deadline
 * @returns {Promise<Object>} billing_id, billing
 */

const calcBilling = function(
  idToken,
  userID,
  year,
  month,
  startDate,
  endDate,
  create,
  deadline
) {
  return call("calcBilling", idToken, {
    user_id: userID,
    year: year,
    month: month,
    start_date: startDate,
    end_date: endDate,
    create: create,
    deadline: deadline
  });
};

/**
 * get billings
 * @param {string} userID
 * @param {string} status ?(unpaid,paid,cancelled)
 * @returns {Promise<Object>} user_id,billing_ids, billings
 */

const getBillings = function(idToken, userID, status) {
  return call("getBillings", idToken, {
    user_id: userID,
    status: status
  });
};

/**
 * get billing
 * @param {string} billingID
 * @returns {Promise<Object>} billing_id, billing
 */

const getBilling = function(idToken, billingID) {
  return call("getBilling", idToken, {
    billing_id: billingID
  });
};

/**
 * update billing status
 * @param {string} billingID
 * @param {string} status ?(unpaid,paid,cancelled)
 * @returns {Promise<String>} success
 */

const updateBillingStatus = function(idToken, billingID, status) {
  return call("updateBillingStatus", idToken, {
    billing_id: billingID,
    status: status
  });
};

export { calcBilling, getBillings, getBilling, updateBillingStatus };
