import { call } from ".";

/**
 * check user is admin
 * @param {string} idToken
 * @returns {Promise<boolean>} true if user is admin
 */
const isAdmin = function(idToken) {
  return call("isAdmin", idToken);
};

/**
 * get user data by user id or email
 * @param {string} idToken
 * @param {string} userID
 * @param {string} email
 * @param {string} phone_number
 * @returns {Promise<Object>} user_id and user
 */
const getUser = function(idToken, userID, email, phone_number) {
  return call("getUser", idToken, {
    user_id: userID,
    email: email,
    phone_number: phone_number
  });
};

/**
 * get users data by ID
 * @param {string} idToken
 * @param {array} userIDs
 * @returns {Promise<Object>} user_ids and users
 */
const getUsers = function(idToken, userIDs) {
  return call("getUsers", idToken, {
    user_ids: userIDs
  });
};

/**
 * set user data
 * @param {string} idToken
 * @param {string} userID
 * @param {Object} setData include first_name , last_name, phone_number
 * @returns {Promise<string>} success
 */
const setUser = function(idToken, userID, setData) {
  return call("setUser", idToken, {
    user_id: userID,
    set_data: setData
  });
};

/**
 * add user admin privilege
 * @param {string} idToken
 * @param {string} userID
 * @param {string} name admin privilege name
 * @param {Date} startTime
 * @param {Date} stopTime
 * @returns {Promise<string>} success
 */
const addAdminPrivilege = function(idToken, userID, name, startTime, stopTime) {
  return call("addAdminPrivilege", idToken, {
    user_id: userID,
    admin_privilege_name: name,
    start_time: startTime,
    stop_time: stopTime
  });
};

/**
 * delete user admin privilege
 * @param {string} idToken
 * @param {string} userID
 * @param {string} name admin privilege name
 * @returns {Promise<string>} success
 */
const deleteAdminPrivilege = function(idToken, userID, name) {
  return call("deleteAdminPrivilege", idToken, {
    user_id: userID,
    admin_privilege_name: name
  });
};

/**
 * get user admin privileges
 * @param {string} idToken
 * @param {string} userID
 * @returns {Promise<Object>} user_id, admin_privilege_ids and admin_privileges
 */
const getAdminPrivileges = function(idToken, userID) {
  return call("getAdminPrivileges", idToken, {
    user_id: userID
  });
};

/**
 * get users admin privileges
 * @param {string} idToken
 * @returns {Promise<Object>} user_ids, admin_privilege_ids and admin_privileges
 */
const getUsersAdminPrivileges = function(idToken) {
  return call("getUsersAdminPrivileges", idToken);
};

/**
 * set user level
 * @param {string} idToken
 * @param {string} userID
 * @param {string} name level name
 * @param {Date} startTime
 * @param {Date} stopTime
 * @returns {Promise<string>} success
 */
const setUserLevel = function(idToken, userID, name, startTime, stopTime) {
  return call("setUserLevel", idToken, {
    user_id: userID,
    level_name: name,
    start_time: startTime,
    stop_time: stopTime
  });
};

/**
 * get user level
 * @param {string} idToken
 * @param {string} userID
 * @returns {Promise<Object>} user_id and user_level
 */
const getUserLevel = function(idToken, userID) {
  return call("getUserLevel", idToken, {
    user_id: userID
  });
};

/**
 * get user ids by level
 * @param {string} idToken
 * @param {string} name level name
 * @returns {Promise<Object>} level_name and user_ids
 */
const getUserIDsByLevel = function(idToken, name) {
  return call("getUserIDsByLevel", idToken, {
    level_name: name
  });
};

/**
 * get user ids,user info by level
 * @param {string} idToken
 * @param {string} name level name
 * @returns {Promise<Object>} level_name and user_ids and users and user_levels
 */
const getUsersByLevel = function(idToken, name) {
  return call("getUsersByLevel", idToken, {
    level_name: name
  });
};

export {
  isAdmin,
  getUser,
  getUsers,
  setUser,
  addAdminPrivilege,
  deleteAdminPrivilege,
  getAdminPrivileges,
  getUsersAdminPrivileges,
  setUserLevel,
  getUserLevel,
  getUserIDsByLevel,
  getUsersByLevel
};
