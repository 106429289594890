import { call } from ".";

/**
 * get orders
 * @param {string} idToken
 * @param {string} userID
 * @param {string} storeID
 * @param {string} consumerID
 * @param {string} status
 * @param {Date} startTime
 * @param {Date} endTime
 * @returns {Promise<Object>} order_ids，orders
 */

const getOrders = function(
  idToken,
  userID,
  storeID,
  consumerID,
  status,
  startTime,
  endTime
) {
  return call("getOrders", idToken, {
    user_id: userID,
    store_id: storeID,
    consumer_id: consumerID,
    status: status,
    start_time: startTime,
    end_time: endTime
  });
};

/**
 * get orders
 * @param {string} idToken
 * @param {string} orderID
 * @returns {Promise<Object>} order_id，order
 */

const getOrder = function(idToken, orderID) {
  return call("getOrder", idToken, {
    order_id: orderID
  });
};

export { getOrders, getOrder };
