import { call } from ".";

/**
 * get user stores
 * @param {string} idToken
 * @param {string} userID
 * @returns {Promise<Object>} user_id, store_ids and stores
 */
const getUserStores = function(idToken, userID) {
  return call("getUserStores", idToken, {
    user_id: userID
  });
};

/**
 * get store
 * @param {string} idToken
 * @param {string} storeID
 * @returns {Promise<Object>} store_id and store
 */
const getStore = function(idToken, storeID) {
  return call("getStore", idToken, {
    store_id: storeID
  });
};

/**
 * set store industry type
 * @param {string} idToken
 * @param {string} storeID
 * @param {string} industryType
 * @returns {Promise<String>} success
 */
const setStoreIndustryType = function(idToken, storeID, industryType) {
  return call("setStoreIndustryType", idToken, {
    store_id: storeID,
    industry_type: industryType
  });
};

/**
 * get store categories
 * @param {string} idToken
 * @param {string} storeID
 * @returns {Promise<Object>} store_id, category_ids and categories
 */
const getStoreCategories = function(idToken, storeID) {
  return call("getStoreCategories", idToken, {
    store_id: storeID
  });
};

/**
 * get store items
 * @param {string} idToken
 * @param {string} storeID
 * @param {boolean} published
 * @returns {Promise<Object>} store_id, item_ids and items
 */
const getStoreItems = function(idToken, storeID, published) {
  return call("getStoreItems", idToken, {
    store_id: storeID,
    published: published
  });
};

/**
 * get category
 * @param {string} idToken
 * @param {string} storeID
 * @param {string} categoryID
 * @returns {Promise<Object>} store_id, category_id and category
 */
const getCategory = function(idToken, storeID, categoryID) {
  return call("getCategory", idToken, {
    store_id: storeID,
    category_id: categoryID
  });
};

/**
 * get category items
 * @param {string} idToken
 * @param {string} storeID
 * @param {string} categoryID
 * @param {boolean} published
 * @returns {Promise<Object>} store_id, category_id, item_ids and items
 */
const getCategoryItems = function(idToken, storeID, categoryID, published) {
  return call("getCategoryItems", idToken, {
    store_id: storeID,
    category_id: categoryID,
    published: published
  });
};

/**
 * get item
 * @param {string} idToken
 * @param {string} itemID
 * @returns {Promise<Object>} item_id and item
 */
const getItem = function(idToken, itemID) {
  return call("getItem", idToken, {
    item_id: itemID
  });
};

/**
 * get payment method data - store
 * @param {string} idToken
 * @param {string} storeID
 * @returns {Promise<Object>} store_id and data
 */
const getPaymentMethodStoreData = function(idToken, storeID) {
  return call("getPaymentMethodStoreData", idToken, {
    store_id: storeID
  });
};

/**
 * get payment method data - tappay
 * @param {string} idToken
 * @param {string} storeID
 * @returns {Promise<Object>} store_id and data
 */
const getPaymentMethodTapPayData = function(idToken, storeID) {
  return call("getPaymentMethodTapPayData", idToken, {
    store_id: storeID
  });
};

/**
 * get shipping channel data - store
 * @param {string} idToken
 * @param {string} storeID
 * @returns {Promise<Object>} store_id and data
 */
const getShippingChannelStoreData = function(idToken, storeID) {
  return call("getShippingChannelStoreData", idToken, {
    store_id: storeID
  });
};

/**
 * get messenger data - telegram bot
 * @param {string} idToken
 * @param {string} storeID
 * @returns {Promise<Object>} store_id and data
 */
const getMessengerTelegramBotData = function(idToken, storeID) {
  return call("getMessengerTelegramBotData", idToken, {
    store_id: storeID
  });
};

/**
 * get create store application
 * @param {string} idToken
 * @param {string} id application form id
 * @returns {Promise<Object>} create_store_application_form_id and create_store_application_form
 */
const getCreateStoreApplicationForm = function(idToken, id) {
  return call("getCreateStoreApplicationForm", idToken, {
    create_store_application_form_id: id
  });
};

/**
 * get create store applications by user id or status
 * @param {string} idToken
 * @param {string} userID
 * @param {string} status
 * @returns {Promise<Object>} user_id, status, create_store_application_form_ids and create_store_application_forms
 */
const getCreateStoreApplicationForms = function(idToken, userID, status) {
  return call("getCreateStoreApplicationForms", idToken, {
    user_id: userID,
    status: status
  });
};

/**
 * reply create store application form
 * @param {string} idToken
 * @param {string} id application form id
 * @param {boolean} accept
 * @param {object} fieldsStatus {[filed_name]status}
 * @param {string} description
 * @returns {Promise<Object>} create_store_application_form_id and store_id
 */
const replyCreateStoreApplicationForm = function(
  idToken,
  id,
  accept,
  fieldsStatus,
  description
) {
  return call("replyCreateStoreApplicationForm", idToken, {
    create_store_application_form_id: id,
    accept: accept,
    fields_status: fieldsStatus,
    description: description
  });
};

export {
  getUserStores,
  getStore,
  setStoreIndustryType,
  getStoreCategories,
  getStoreItems,
  getCategory,
  getCategoryItems,
  getItem,
  getPaymentMethodStoreData,
  getPaymentMethodTapPayData,
  getShippingChannelStoreData,
  getMessengerTelegramBotData,
  getCreateStoreApplicationForm,
  getCreateStoreApplicationForms,
  replyCreateStoreApplicationForm
};
