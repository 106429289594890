import { adminAPI } from "@/plugins/service";

export const state = {
  id: "",
  data: null,
  info: null,
  planList: [],
  planUsers: [],
  planUsersPlanData: [],
  stores: [],
  forms: [],
  userPlan: null,
  billings: [],
  orders: [],
  orderData: {}
};
export const getters = {
  id(state) {
    return state.id;
  },
  data(state) {
    return state.data;
  },
  info(state) {
    return state.info;
  },
  list(state) {
    const list = [];
    for (const id of state.ids) {
      list.push({ id, data: state.datas[id] });
    }
    return list;
  },
  planList(state) {
    return state.planList;
  },
  planUsers(state) {
    return state.planUsers;
  },
  planUsersPlanData(state) {
    return state.planUsersPlanData;
  },
  userPlan(state) {
    return state.userPlan;
  },
  stores(state) {
    return state.stores;
  },
  forms(state) {
    return state.forms;
  },
  billings(state) {
    return state.billings;
  },
  orders(state) {
    return state.orders;
  },
  orderData(state) {
    return state.orderData;
  }
};
export const mutations = {
  SET_USER_ID(state, id) {
    state.id = id;
  },
  CLEAR_STORES(state) {
    state.stores = [];
  },
  PUSH_STORES(state, { id, data }) {
    const foundIndex = state.stores.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.stores.splice(foundIndex, 1, { id, data });
    else state.stores.push({ id, data });
  },
  CLEAR_FORMS(state) {
    state.forms = [];
  },
  PUSH_FORMS(state, { id, data }) {
    const foundIndex = state.forms.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.forms.splice(foundIndex, 1, { id, data });
    else state.forms.push({ id, data });
  },
  CLEAR_BILLINGS(state) {
    state.billings = [];
  },
  PUSH_BILLINGS(state, { id, data }) {
    const foundIndex = state.billings.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.billings.splice(foundIndex, 1, { id, data });
    else state.billings.push({ id, data });
  },
  SET_USER_INFO(state, info) {
    state.info = info;
  },
  SET_PLAN_LIST(state, plan) {
    state.planList = plan;
  },
  SET_PLAN_USERS(state, users) {
    state.planUsers = users;
  },
  SET_PLAN_USERS_PLAN(state, plans) {
    state.planUsersPlanData = plans;
  },
  SET_USER_PLAN(state, plan) {
    state.userPlan = plan;
  },
  CLEAR_ORDERS(state) {
    state.orders = [];
  },
  PUSH_ORDERS(state, { id, data }) {
    const foundIndex = state.orders.findIndex(s => s.id == id);
    if (foundIndex >= 0) state.orders.splice(foundIndex, 1, { id, data });
    else state.orders.push({ id, data });
  },
  SET_ORDER_DATA(state, { id, data }) {
    state.orderData = { id, data };
  }
};
export const actions = {
  fetchUserInfo({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      if (id) {
        commit("SET_USER_ID", id);
        return dispatch("getUser")
          .then(() => {
            return dispatch("getUserStores");
          })
          .then(() => {
            return dispatch("getBillings");
          })
          .then(() => {
            resolve();
          })
          .catch(err => {
            dispatch("fetchUserInfo", "");
            reject(err);
          });
      } else {
        commit("SET_USER_ID", "");
        commit("CLEAR_FORMS");
        commit("CLEAR_STORES");
        commit("SET_USER_PLAN", null);
        resolve();
      }
    });
  },
  getUser({ rootGetters, dispatch, getters, commit }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = getters.id;
          return adminAPI.getUser(idToken, userID);
        })
        .then(res => {
          commit("SET_USER_INFO", res.user);
          resolve();
        })
        .catch(err => reject("get user error: " + err));
    });
  },
  getUserStores({ rootGetters, dispatch, getters, commit }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = getters.id;
          return adminAPI.getUserStores(idToken, userID);
        })
        .then(res => {
          commit("CLEAR_STORES");
          commit("CLEAR_FORMS");
          res.store_ids.forEach(storeID => {
            commit("PUSH_STORES", {
              id: storeID,
              data: res["stores"][storeID]
            });
            if (res["stores"][storeID]["create_store_application_form_id"]) {
              let formID =
                res["stores"][storeID]["create_store_application_form_id"];
              return dispatch("getCreateStoreApplicationForm", formID);
            }
          });
          resolve();
        })
        .catch(err => reject("get user stores error: " + err));
    });
  },
  getCreateStoreApplicationForm({ rootGetters, dispatch, commit }, formID) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.getCreateStoreApplicationForm(idToken, formID);
        })
        .then(res => {
          commit("PUSH_FORMS", {
            id: res["create_store_application_form_id"],
            data: res["create_store_application_form"]
          });
          resolve();
        })
        .catch(err =>
          reject("get create store application form error: " + err)
        );
    });
  },
  getPlans({ rootGetters, dispatch, commit }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.getPlans(idToken, "published");
        })
        .then(res => {
          let plans = [];
          for (const planID of res["plan_ids"]) {
            plans.push({
              id: planID,
              name: res.plans[planID].name
            });
          }
          commit("SET_PLAN_LIST", plans);
          resolve();
        })
        .catch(err => reject("get plans error: " + err));
    });
  },
  getUsersByPlan({ rootGetters, dispatch, commit }, planID) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      let userIDs = [];
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.getUserIDsByPlan(idToken, planID);
        })
        .then(res => {
          userIDs = res["user_ids"];
          if (!userIDs.length) resolve();
          return dispatch("admin/getUserIDToken", {}, { root: true });
        })
        .then(idToken => {
          return adminAPI.getUsers(idToken, userIDs);
        })
        .then(res => {
          let users = [];
          for (const userID of res["user_ids"]) {
            users.push({
              id: userID,
              data: res.users[userID]
            });
          }
          commit("SET_PLAN_USERS", users);
          resolve();
        })
        .catch(err => reject("get users by plan error: " + err));
    });
  },
  getUserPlansByPlan({ rootGetters, dispatch, commit }, planID) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.getUserPlansByPlan(idToken, planID);
        })
        .then(res => {
          commit("SET_PLAN_USERS_PLAN", res);
          resolve();
        })
        .catch(err => {
          commit("SET_PLAN_USERS_PLAN", []);
          reject("get user plans by plan error: " + err);
        });
    });
  },
  getUserPlan({ rootGetters, dispatch, getters, commit }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      let plan = {};
      let planID = "";
      commit("SET_USER_PLAN", null);
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = getters.id;
          return adminAPI.getUserPlan(idToken, userID);
        })
        .then(res => {
          plan = res;
          planID = res["plan_id"];
          return dispatch("admin/getUserIDToken", {}, { root: true });
        })
        .then(idToken => {
          return adminAPI.getPlan(idToken, planID);
        })
        .then(res => {
          plan.name = res.plan.name;
          commit("SET_USER_PLAN", plan);
          resolve();
        })
        .catch(err => reject("get user plan error: " + err));
    });
  },
  setUserPlan(
    { rootGetters, dispatch },
    { userID, planID, startTime, endTime }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.setUserPlan(
            idToken,
            userID,
            planID,
            startTime,
            endTime
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("set user plan error: " + err));
    });
  },
  suspendUserPlan({ rootGetters, dispatch }, { userID, suspend }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.suspendUserPlan(idToken, userID, suspend);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("suspend user plan error: " + err));
    });
  },
  getBillings({ dispatch, getters, commit }, status) {
    return dispatch("admin/getUserIDToken", {}, { root: true })
      .then(idToken => {
        const userID = getters.id;
        return adminAPI.getBillings(idToken, userID, status);
      })
      .then(res => {
        commit("CLEAR_BILLINGS");
        for (const billingID of res["billing_ids"]) {
          commit("PUSH_BILLINGS", {
            id: billingID,
            data: res["billings"][billingID]
          });
        }
      })
      .catch(err => Promise.reject("get billings error: " + err));
  },
  calcBilling(
    { dispatch, getters, rootGetters },
    { year, month, startDate, endDate, create, deadline }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = getters.id;
          return adminAPI.calcBilling(
            idToken,
            userID,
            year,
            month,
            startDate,
            endDate,
            create,
            deadline
          );
        })
        .then(() => {
          resolve();
        })
        .catch(err => Promise.reject("calc billing error: " + err));
    });
  },
  updateBillingStatus({ dispatch, rootGetters }, { billingID, status }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.updateBillingStatus(idToken, billingID, status);
        })
        .then(() => {
          resolve();
        })
        .catch(err => Promise.reject("update billing status error: " + err));
    });
  },
  getOrders(
    { dispatch, getters, commit, rootGetters },
    { storeID, startTime, endTime }
  ) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          const userID = getters.id;
          let status = "all";
          let consumerID = "";
          return adminAPI.getOrders(
            idToken,
            userID,
            storeID,
            consumerID,
            status,
            startTime,
            endTime
          );
        })
        .then(res => {
          commit("CLEAR_ORDERS");
          for (const orderID of res["order_ids"]) {
            commit("PUSH_ORDERS", {
              id: orderID,
              data: res.orders[orderID]
            });
          }
          resolve();
        })
        .catch(err => reject("get orders error: " + err));
    });
  },
  getOrder({ dispatch, commit, rootGetters }, orderID) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.getOrder(idToken, orderID);
        })
        .then(res => {
          commit("SET_ORDER_DATA", { id: res["order_id"], data: res.order });
          resolve();
        })
        .catch(err => reject("get order error: " + err));
    });
  },
  setStoreIndustryType({ dispatch, rootGetters }, { storeID, industryType }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.setStoreIndustryType(idToken, storeID, industryType);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("set store industry type error: " + err));
    });
  }
};
