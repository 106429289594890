import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";

import DatePicker from "@/components/DatePicker";
Vue.component("date-picker", DatePicker);
import currencyFilter from "./filters/currency.js";
Vue.filter("currency", currencyFilter);

import LoadingOverlay from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.component("loading-overlay", LoadingOverlay);

import AlertDialog from "@/components/AlertDialog";
Vue.component("AlertDialog", AlertDialog);
Vue.config.productionTip = false;

let app;
store.dispatch("admin/onAuthStateChanged", () => {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  }
});
