import { ServiceError, callService } from "../..";

const ServiceURL = "https://api-admin-service-th4n4u7taa-an.a.run.app";

/**
 * call api
 * @param {string} api
 * @param {string} idToken
 * @param {Object} data
 * @returns {Promise<any>}
 */
export const call = function(api, idToken, data = {}) {
  return callService(`${ServiceURL}/api/${api}`, idToken, data).then(res => {
    return new Promise((resolve, reject) => {
      if (res.ok) resolve(res.result);
      else reject(new ServiceError(res.error_code, res.description));
    });
  });
};

export {
  isAdmin,
  getUser,
  setUser,
  getUsers,
  addAdminPrivilege,
  deleteAdminPrivilege,
  getAdminPrivileges,
  getUsersAdminPrivileges,
  setUserLevel,
  getUserLevel,
  getUserIDsByLevel,
  getUsersByLevel
} from "./user";

export {
  getUserStores,
  getStore,
  setStoreIndustryType,
  getStoreCategories,
  getStoreItems,
  getCategory,
  getCategoryItems,
  getItem,
  getPaymentMethodStoreData,
  getPaymentMethodTapPayData,
  getShippingChannelStoreData,
  getMessengerTelegramBotData,
  getCreateStoreApplicationForm,
  getCreateStoreApplicationForms,
  replyCreateStoreApplicationForm
} from "./store";

export { getOrders, getOrder } from "./order";

export {
  addPlan,
  setPlan,
  publishPlan,
  delistPlan,
  deletePlan,
  getPlan,
  getPlans,
  setUserPlan,
  suspendUserPlan,
  getUserPlan,
  getUserPlanHistories,
  getUserIDsByPlan,
  getUserPlansByPlan
} from "./plan";

export {
  calcBilling,
  getBillings,
  getBilling,
  updateBillingStatus
} from "./billing";
