import { adminAPI } from "@/plugins/service";

export const state = {
  id: "",
  data: null,
  planName: ""
};

export const getters = {
  id(state) {
    return state.id;
  },
  data(state) {
    return state.data;
  },
  planName(state) {
    return state.planName;
  }
};

export const mutations = {
  SET_BILLING_ID(state, id) {
    state.id = id;
  },
  SET_BILLING_DATA(state, data) {
    state.data = data;
  },
  SET_PLAN_NAME(state, planName) {
    state.planName = planName;
  }
};

export const actions = {
  fetchBilling({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      if (id) {
        commit("SET_BILLING_ID", id);
        return dispatch("getBilling")
          .then(() => {
            resolve();
          })
          .catch(err => {
            dispatch("fetchBilling", "");
            reject(err);
          });
      } else {
        commit("SET_BILLING_ID", "");
        commit("SET_BILLING_DATA", null);
        resolve();
      }
    });
  },
  getBilling({ rootGetters, dispatch, getters, commit }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.getBilling(idToken, getters.id);
        })
        .then(res => {
          let data = res.billing;
          commit("SET_BILLING_DATA", data);
          let planID = res.billing["plan_id"];
          return dispatch("getPlanName", planID);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("get billing error: " + err));
    });
  },
  getPlanName({ rootGetters, dispatch, commit }, planID) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.getPlan(idToken, planID);
        })
        .then(res => {
          commit("SET_PLAN_NAME", res.plan.name);
          resolve();
        })
        .catch(err => reject("get plan name error: " + err));
    });
  }
};
