import { adminAPI } from "@/plugins/service";

export const state = {
  ids: [],
  datas: {}
};
export const getters = {
  ids(state) {
    return state.ids;
  },
  datas(state) {
    return state.datas;
  },
  list(state) {
    const list = [];
    for (const id of state.ids) {
      list.push({ id, data: state.datas[id] });
    }
    return list;
  }
};
export const mutations = {
  SET(state, { ids, datas }) {
    state.ids = ids;
    state.datas = datas;
  }
};
export const actions = {
  getChargePlans({ commit, dispatch }, status) {
    return dispatch("admin/getUserIDToken", {}, { root: true })
      .then(idToken => {
        return adminAPI.getPlans(idToken, status);
      })
      .then(res => {
        const ids = res["plan_ids"];
        const datas = res["plans"];
        return commit("SET", { ids, datas });
      })
      .catch(err => Promise.reject("get plans error: " + err));
  },
  createPlan({ rootGetters, dispatch }, { name, setData }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      let planID = "";
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.addPlan(idToken, name);
        })
        .then(res => {
          planID = res["plan_id"];
          return dispatch("admin/getUserIDToken", {}, { root: true });
        })
        .then(idToken => {
          return adminAPI.setPlan(idToken, planID, setData);
        })
        .then(() => {
          resolve(planID);
        })
        .catch(err => reject("create plan error: " + err));
    });
  },
  setPlan({ rootGetters, dispatch }, { planID, setData }) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.setPlan(idToken, planID, setData);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("set plan error: " + err));
    });
  },
  publishPlan({ rootGetters, dispatch }, planID) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.publishPlan(idToken, planID);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("publish plan error: " + err));
    });
  },
  delistPlan({ rootGetters, dispatch }, planID) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.delistPlan(idToken, planID);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("delist plan error: " + err));
    });
  },
  deletePlan({ rootGetters, dispatch }, planID) {
    return new Promise((resolve, reject) => {
      if (!rootGetters["admin/isSignIn"]) reject("no sign in");
      dispatch("admin/getUserIDToken", {}, { root: true })
        .then(idToken => {
          return adminAPI.deletePlan(idToken, planID);
        })
        .then(() => {
          resolve();
        })
        .catch(err => reject("delete plan error: " + err));
    });
  }
};
