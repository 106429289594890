import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "@/plugins/firebase";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch(err => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("@/views/SignIn.vue"),
    meta: {
      requiresAuth: false
    }
  },
  // manage user
  {
    path: "/search-user",
    name: "SearchUser",
    component: () => import("@/views/ManageUserTools/SearchUser.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/user-info/:id",
    name: "UserInfo",
    component: () => import("@/views/ManageUserTools/UserInfo.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/create-store-applications",
    name: "CreateStoreApplications",
    component: () =>
      import("@/views/ManageUserTools/CreateStoreApplications.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/create-store-application/:id",
    name: "CreateStoreApplication",
    component: () =>
      import("@/views/ManageUserTools/CreateStoreApplication.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/user-plan",
    name: "UserPlan",
    component: () => import("../views/ManageUserTools/UserPlan.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/user-billing/:id",
    name: "UserBilling",
    component: () => import("../views/ManageUserTools/UserBilling.vue"),
    meta: {
      requiresAuth: true
    }
  },
  // admin tools
  {
    path: "/admin-tools",
    name: "AdminTools",
    component: () => import("@/views/AdminTools/AdminTools.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/manage-privileges",
    name: "ManagePrivileges",
    component: () => import("@/views/AdminTools/ManagePrivileges.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/manage-charge-plan",
    name: "ManageChargePlan",
    component: () => import("@/views/AdminTools/ManageChargePlan.vue"),
    meta: {
      requiresAuth: true
    }
  },
  // sales tool
  {
    path: "/sales-tools",
    name: "SalesTools",
    component: () => import("@/views/SalesTools/SalesTools.vue"),
    meta: {
      requiresAuth: true
    }
  },
  // manage message push tool
  {
    path: "/manage-message-push-tools",
    name: "ManageMessagePushTools",
    component: () =>
      import("@/views/ManageMessagePushTools/ManageMessagePushTools.vue"),
    meta: {
      requiresAuth: true
    }
  },
  // user tools
  {
    path: "/modify-info",
    name: "ModifyInfo",
    component: () => import("@/views/UserTools/ModifyInfo.vue"),
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!currentUser) {
      next({ name: "SignIn", query: { redirect: to.fullPath } });
      return;
    }
  }
  next();
});

export default router;
